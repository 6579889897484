body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: black;
}

a:hover {
  color: black;
  text-decoration: underline;
}

.banner-hr {
  width: 80%;
}

.text-container {
  width: 60%;
  display: block;
  margin: auto;
}

.banner-card {
  width: 70%
}

.banner-icon {
  font-size: 50px;
  display: block;
  margin: auto;
  text-align: center;
}

.home-card-img {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.card-logo {
  display: block;
  margin: auto;
}

.login-logo {
  display: block;
  margin: auto;
}

.save-btn {
  width: 100%;
}

.date-picker {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-button {
  font-size: 15px;
}

.invisible {
  display: none
}

.feature-img {
  max-width: 100%;
}

.submit-btn {
  width: 100%
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
}